import { Component, Vue } from 'vue-property-decorator'

// services
import { api } from '@/services/api'

import axios from 'axios'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//wrappers
import { WForm, Padding, Loader, WTable, Sidebar } from '@/components/wrappers';


@Component({
	components:{
    PrivateContent,
		WForm,
		Padding,
		Loader,
		WTable,
		Sidebar
	}
})
export default class File extends Vue {
  
  async mounted(){
    document.title = 'Arquivo';
    this.getFile3()
	}

  getFile3(){
    const url = "http://espiritocidadao.impaire.com.br:5000/uploads/espiritocidadao.impaire.com.br/2021/06/06/d0096823fffd82a60e6840f34ea21e2fe96b7b95.jpeg";

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);

    // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    // xhr.setRequestHeader("Accept", "*/*");
    // xhr.setRequestHeader("Accept-Encoding", "gzip, deflate, br");
    // xhr.setRequestHeader("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlc3Bpcml0b2NpZGFkYW8uaW1wYWlyZS5jb20uYnIiLCJ1aWQiOiJNUT09IiwiZXhwIjoxNjI1MTM5MjMyLCJpYXQiOjE2MjIxMTUyMzJ9.bhgQEVm6CR7H_rrRVl6qBplTcdlV07lIJC98E2F-Oyo");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
          console.log(xhr.status);
          console.log(xhr.responseText);
      }};

    xhr.send();

  }

  getFile2(){
    axios({
      url: 'http://espiritocidadao.impaire.com.br:5000/uploads/espiritocidadao.impaire.com.br/2021/06/06/d0096823fffd82a60e6840f34ea21e2fe96b7b95.jpeg', //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': '*',
        "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlc3Bpcml0b2NpZGFkYW8uaW1wYWlyZS5jb20uYnIiLCJ1aWQiOiJNUT09IiwiZXhwIjoxNjI1MTM5MjMyLCJpYXQiOjE2MjIxMTUyMzJ9.bhgQEVm6CR7H_rrRVl6qBplTcdlV07lIJC98E2F-Oyo"
      }
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'file.pdf'); //or any other extension
       document.body.appendChild(link);
       link.click();
    })
    .catch( (error) => {
      console.log(error);
      
    });
    
  }

  getFile(){
    const url = 'http://espiritocidadao.impaire.com.br:5000/uploads/espiritocidadao.impaire.com.br/2021/06/06/d0096823fffd82a60e6840f34ea21e2fe96b7b95.jpeg'
    api.get(url)
      .then( (response) => {
        console.log(response);
        
      })
      .catch( (error) => {
        console.log(error);
        
      })
  }

}
